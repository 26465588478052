import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { ChromePicker } from "react-color";
import Loader from "react-loader";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { FaInfoCircle } from "react-icons/fa";

import CREATEBTN from "../create_chart_btn.png";
import Header from "./Header";
import GetDataForChart from "./GetDataForChart";

const { tableau } = window;

const defaultIntervalInMin = "5";

const GetStarted = () => {
  const navigate = useNavigate();
  // const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [parent, setParent] = useState();

  const [dimensionTextBoxes, setDimensionTextBoxes] = useState();

  const [sheet, setSheet] = useState();
  const [dashboardSheets, setDashboardSheets] = useState();

  const [sheetName, setSheetName] = useState("");
  const [headers, setHeaders] = useState([]);
  const [worksheetData, setWorksheetData] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [metrics, setMetrics] = useState();

  const [dimension1, setDimension1] = useState();
  const [dimension2, setDimension2] = useState();
  const [dimension3, setDimension3] = useState();
  const [metric1, setMetric1] = useState();
  const [metric2, setMetric2] = useState();

  const [newArrayOfData, setNewArrayOfData] = useState([]);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  //const [defaultColor, setDefaultColor] = useState("#999");
  const [changeColor, setChangeColor] = useState("#CB8408");
  const [color, setColor] = useState({
    r: "0",
    g: "9",
    b: "153",
    a: "1",
  });

  const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
  //const [defaultColor2, setDefaultColor2] = useState("#999");
  const [changeColor2, setChangeColor2] = useState("#467EC1");
  const [color2, setColor2] = useState({
    r: "0",
    g: "9",
    b: "153",
    a: "1",
  });

  const [displayColorPicker3, setDisplayColorPicker3] = useState(false);
  //const [defaultColor3, setDefaultColor3] = useState("#999");
  const [changeColor3, setChangeColor3] = useState("#197D7B");
  const [color3, setColor3] = useState({
    r: "0",
    g: "9",
    b: "153",
    a: "1",
  });

  const [displayColorPicker4, setDisplayColorPicker4] = useState(false);
  //const [defaultColor4, setDefaultColor4] = useState("#999");
  const [changeColor4, setChangeColor4] = useState("#8A8A43");
  const [color4, setColor4] = useState({
    r: "0",
    g: "9",
    b: "153",
    a: "1",
  });

  const [metricsOptions, setMetricsOptions] = useState();

  const [saveSettings, setSaveSettings] = useState("No");

  const [unit1, setUnit1] = useState();
  const [unit2, setUnit2] = useState();

  const [parentArray, setParentArray] = useState();
  const [dimension1Array, setDimension1Array] = useState([]);
  const [dimension2Array, setDimension2Array] = useState([]);
  const [dimension3Array, setDimension3Array] = useState([]);

  const [metric1Array, setMetric1Array] = useState([]);
  const [metric2Array, setMetric2Array] = useState([]);
  const [finalJsonArray, setFinalJsonArray] = useState([]);

  const [showChart, setShowChart] = useState(false);

  const [columns, setColumns] = useState([]);
  const [chartWorkSheetData, setChartWorkSheetData] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [showDiv, setShowDiv] = useState("none");

  useEffect(() => {
    //document.getElementById("showDimensionsBox").style.display = "none";
    // document.getElementById("showMetricsBox").style.display = "none";
    // document.getElementById("showSettings").style.display = "none";
    // document.getElementById("showBtn").style.display = "none";

    tableau.extensions.initializeAsync({ configure: configure }).then(() => {
      let dashboardSheets =
        tableau.extensions.dashboardContent.dashboard.worksheets.map(
          (worksheet) => {
            return <option value={worksheet.name}>{worksheet.name}</option>;
          }
        );
      setDashboardSheets(dashboardSheets);

      let saveSettings = tableau.extensions.settings.get("saveSettings");
      setSaveSettings(saveSettings);
      let dimensions = tableau.extensions.settings.get("dimensions");
      //let metrics = tableau.extensions.settings.get("metrics");

      let dimensionsLength = JSON.parse(dimensions).length;
      // let metricsLength = JSON.parse(metrics).length;

      if (saveSettings === "Yes") {
        if (dimensionsLength === 4) {
          let sheetName = tableau.extensions.settings.get("sheetName");
          let columns = tableau.extensions.settings.get("columns");
          let chartWorkSheetData = tableau.extensions.settings.get("chartWorkSheetData");
          let metric1 = tableau.extensions.settings.get("metric1");
          let metric2 = tableau.extensions.settings.get("metric2");
          let parent = tableau.extensions.settings.get("parent");
          let dimension1 = tableau.extensions.settings.get("dimension1");
          let dimension2 = tableau.extensions.settings.get("dimension2");
          let dimension3 = tableau.extensions.settings.get("dimension3");
          let changeColor = tableau.extensions.settings.get("changeColor");
          let changeColor2 = tableau.extensions.settings.get("changeColor2");
          let changeColor3 = tableau.extensions.settings.get("changeColor3");
          let changeColor4 = tableau.extensions.settings.get("changeColor4");
          let unit1 = tableau.extensions.settings.get("unit1");
          let unit2 = tableau.extensions.settings.get("unit2");
          let showChart = tableau.extensions.settings.get("showChart");



          setSheetName(sheetName);

          setColumns(columns);

          setChartWorkSheetData(chartWorkSheetData);

          setMetric1(metric1);

          setMetric2(metric2);

          setParent(parent);

          setDimension1(dimension1);

          setDimension2(dimension2);

          setDimension3(dimension3);

          setChangeColor(changeColor);

          setChangeColor2(changeColor2);

          setChangeColor3(changeColor3);

          setChangeColor4(changeColor4);

          setUnit1(unit1);

          setUnit2(unit2);

          setShowChart(showChart);
        } else if (dimensionsLength === 3) {
          let sheetName = tableau.extensions.settings.get("sheetName");
          let columns = tableau.extensions.settings.get("columns");
          let chartWorkSheetData = tableau.extensions.settings.get("chartWorkSheetData");
          let metric1 = tableau.extensions.settings.get("metric1");
          let metric2 = tableau.extensions.settings.get("metric2");
          let parent = tableau.extensions.settings.get("parent");
          let dimension1 = tableau.extensions.settings.get("dimension1");
          let dimension2 = tableau.extensions.settings.get("dimension2");
         
          let changeColor = tableau.extensions.settings.get("changeColor");
          let changeColor2 = tableau.extensions.settings.get("changeColor2");
          let changeColor3 = tableau.extensions.settings.get("changeColor3");
          let changeColor4 = tableau.extensions.settings.get("changeColor4");
          let unit1 = tableau.extensions.settings.get("unit1");
          let unit2 = tableau.extensions.settings.get("unit2");
          let showChart = tableau.extensions.settings.get("showChart");

          setSheetName(sheetName);

          setColumns(columns);

          setChartWorkSheetData(chartWorkSheetData);

          setMetric1(metric1);

          setMetric2(metric2);

          setParent(parent);

          setDimension1(dimension1);

          setDimension2(dimension2);

          

          setChangeColor(changeColor);

          setChangeColor2(changeColor2);

          setChangeColor3(changeColor3);

          setChangeColor4(changeColor4);

          setUnit1(unit1);

          setUnit2(unit2);

          setShowChart(showChart);
        }

        // const parentArray = tableau.extensions.settings.get("parentArray");
        // const dimension1Array = tableau.extensions.settings.get("dimension1Array");
        // const dimension2Array = tableau.extensions.settings.get("dimension2Array");
        // const dimension3Array = tableau.extensions.settings.get("dimension3Array");
        // const dimensions = tableau.extensions.settings.get("dimensions");

        // const metric1Array = tableau.extensions.settings.get("metric1Array");
        // const metric2Array = tableau.extensions.settings.get("metric2Array");
        // const metrics = tableau.extensions.settings.get("metrics");
        // const finalJsonArray = tableau.extensions.settings.get("finalJsonArray");

        // const newArrayOfData = tableau.extensions.settings.get("newArrayOfData");

        // setParentArray(parentArray);

        // setDimension1Array(dimension1Array);

        // setDimension2Array(dimension2Array);

        // setDimension3Array(dimension3Array);

        // setDimensions(dimensions);

        // setMetric1Array(metric1Array);

        // setMetric2Array(metric2Array);

        // setMetrics(metrics);

        // setFinalJsonArray(finalJsonArray);

        // setNewArrayOfData(newArrayOfData);

        // const redirect = tableau.extensions.settings.get("redirect");
        // setRedirect(redirect);
      }
    });
  }, []);

  const configure = () => {
    // ... code to configure the extension
    // for example, set up and call displayDialogAsync() to create the configuration window
    // and set initial settings (defaultIntervalInMin)
    // and handle the return payload

    let popupUrl = "https://www.decisionfoundry.com";
    // ...
    tableau.extensions.ui
      .displayDialogAsync(popupUrl, defaultIntervalInMin, {
        height: 500,
        width: 500,
      })
      .then((closePayload) => {
        // The promise is resolved when the dialog has been expectedly closed, meaning that
        // the popup extension has called tableau.extensions.ui.closeDialog.
        // ...
        // The close payload is returned from the popup extension via the closeDialog() method.
        // ....
      });
  };

  const onSelectSheet = (e) => {
    setSheet(e.target.value);
    setSheetName(e.target.value);
    //setSaveSettings("Yes")
    setShowDiv("block");

    let dataSource = e.target.value;

    //tableau.extensions.initializeAsync().then(() => {
    //console.log("dataSource", dataSource);
    var worksheet =
      tableau.extensions.dashboardContent.dashboard.worksheets.find(
        (w) => w.name === dataSource
      );
    // worksheet.getUnderlyingTablesAsync().then(logicalTables => {
    //     worksheet.getUnderlyingTableDataAsync(logicalTables[0].id).then(dataTable => {
    //       // process the dataTable...
    //       console.log('Data Table:',dataTable);
    //     });
    // });
    //get the summary data for the sheet
    worksheet.getSummaryDataAsync().then((data) => {
      const worksheetData = data;

      // console.log("worksheetData raw", worksheetData);

      // console.log("worksheetData", worksheetData._data);
      //The getSummaryDataAsync() method returns a DataTable
      //Map the DataTable (worksheetData) into a format for display, etc.

      let columns = worksheetData._columns;

      let headers = [];
      let dimensions = [];
      let metrics = [];

      _.map(columns, (col) => {
        if (col._dataType === "float" || col._dataType === "int") {
          metrics.push(col._fieldName);
        } else {
          dimensions.push(col._fieldName);
        }
        headers.push(col._fieldName);
      });

      // console.log("Dimension:", dimensions);
      // console.log("Metric", metrics);

      let worksheetD = worksheetData._data;

      let newHeaders = [];
      let headerIndex = [];
      let newArrayOfData = [];

      _.map(columns, (dt) => {
        // console.log(dt);

        if (dt._dataType === "string") {
        } else {
          // console.log(dt._fieldName);
          newHeaders.push(dt._fieldName);
          headerIndex.push(dt._index);
        }
      });

      //console.log(newHeaders);
      //console.log(headerIndex);

      _.map(worksheetD, (dt) => {
        // console.log(dt[headerIndex[headerIndex.length-(headerIndex.length)]])

        //var dataObject = [];

        _.map(headerIndex, (hi, index) => {
          // console.log(dt[hi]);
          let unit;
          let currencies = ["£", "¥", "₹", "€", "$"];
          let currencyArray = [];

          _.map(currencies, (dt1) => {
            var currencyIndex = _.indexOf(dt[hi]._formattedValue, dt1);

            //  console.log(currencyIndex)

            currencyArray.push(currencyIndex);
          });

          //console.log("CurrencyArray",currencyArray)

          var metricPercentSearch = _.indexOf(dt[hi]._formattedValue, "%");

          var findIndexofZero = _.indexOf(currencyArray, 0);

          if (findIndexofZero >= 0) {
            unit = currencies[findIndexofZero];
          } else if (metricPercentSearch >= 0) {
            unit = "%";
          } else {
            unit = "";
          }

          var newObject = {
            metric: newHeaders[index],
            value: dt[hi]._value,
            unit: unit,
          };

          // console.log(newObject);

          newArrayOfData.push(newObject);
        });
      });

      setNewArrayOfData(newArrayOfData);

      var unit1;
      var unit2;
      let currencies = ["£", "¥", "₹", "€", "$"];
      let currencyArray1 = [];
      let currencyArray2 = [];

      var sheetSignleLength = worksheetD[0].length;

      //console.log(sheetSignleLength);

      var firstmetricstring = worksheetD[0][sheetSignleLength - 2];
      var secondmetricstring = worksheetD[0][sheetSignleLength - 1];

      _.map(currencies, (dt) => {
        var currencyIndex = _.indexOf(firstmetricstring._formattedValue, dt);

        //console.log(currencyIndex);

        currencyArray1.push(currencyIndex);
      });

      // console.log(currencyArray1);

      _.map(currencies, (dt) => {
        var currencyIndex = _.indexOf(secondmetricstring._formattedValue, dt);

        // console.log(currencyIndex);

        currencyArray2.push(currencyIndex);
      });

      // console.log(currencyArray2);

      //  var firstmetricCurrencySearch = _.indexOf(
      //       firstmetricstring._formattedValue,
      //       "$"
      //     );
      var firstmetricPercentSearch = _.indexOf(
        firstmetricstring._formattedValue,
        "%"
      );
      //     var secondmetricCurrencySearch = _.indexOf(
      //       secondmetricstring._formattedValue,
      //       "$"
      //     );
      var secondmetricPercentSearch = _.indexOf(
        secondmetricstring._formattedValue,
        "%"
      );

      var findIndexofZero1 = _.indexOf(currencyArray1, 0);

      var findIndexofZero2 = _.indexOf(currencyArray2, 0);

      // console.log("findIndexofZero1", findIndexofZero1);
      // console.log("findIndexofZero2", findIndexofZero2);
      // console.log("currencies1", currencies[findIndexofZero1]);
      // console.log("currencies2", currencies[findIndexofZero2]);

      if (findIndexofZero1 >= 0) {
        unit1 = currencies[findIndexofZero1];
      } else if (firstmetricPercentSearch >= 0) {
        unit1 = "%";
      } else {
        unit1 = "";
      }

      if (findIndexofZero2 >= 0) {
        unit2 = currencies[findIndexofZero2];
      } else if (secondmetricPercentSearch >= 0) {
        unit2 = "%";
      } else {
        unit2 = "";
      }

      // console.log("Unit1:", unit1);
      // console.log("Unit2:", unit2);

      setUnit1(unit1);
      setUnit2(unit2);

      let newJsonArray = [];

      let singleArray = [];

      _.map(worksheetD, (jt) => {
        _.map(jt, (newJt) => {
          // console.log("Type Of", typeof newJt._value);
          singleArray.push(newJt._value);
        });
      });
      newJsonArray.push(singleArray);

      let finalJsonArray = _.chunk(newJsonArray[0], headers.length);

      finalJsonArray.unshift(headers);

      setFinalJsonArray(finalJsonArray);

      // console.log("finalJsonArray", finalJsonArray);

      let parentIndex = _.indexOf(headers, dimensions[0]);
      let parentArray = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[parentIndex];
      });

      let uniqParentArray = _.uniq(parentArray);

      // console.log("uniqParentArray:", uniqParentArray);

      uniqParentArray.shift();

      if (dimensions.length > 4) {
        setModal2(!modal2);
      } else if (dimensions.length === 4) {
        if (uniqParentArray.length > 1) {
          setModal(!modal);
        }
      } else if (dimensions.length < 3) {
        setModal3(!modal3);
      }

      setHeaders(headers);
      setDimensions(dimensions);
      setMetrics(metrics);
      setWorksheetData(worksheetD);
      setParent(dimensions[0]);
      setDimension1(dimensions[1]);
      setDimension2(dimensions[2]);
      setDimension3(dimensions[3]);
      setColumns(columns);
      setChartWorkSheetData(worksheetD);

      // let metric1Array;

      // if (metric1 === "") {
      //   //console.log("metric1 not selected");
      // } else {
      //   //console.log("metric1 is selected");

      //   let metric1Index = _.indexOf(headers, metric1);
      //   metric1Array = _.flatMap(finalJsonArray, (oneDArray) => {
      //     return oneDArray[metric1Index];
      //   });
      //   setMetric1Array(metric1Array)
      // }

      // //console.log("metric1Array", metric1Array);

      // let metric2Array;

      // if (metric2 === "") {
      //   // console.log("metric2 not selected");
      // } else {
      //   // console.log("metric2 is selected");

      //   let metric2Index = _.indexOf(headers, metric2);
      //   metric2Array = _.flatMap(finalJsonArray, (oneDArray) => {
      //     return oneDArray[metric2Index];
      //   });
      //   setMetric2Array(metric2Array)
      // }

      //});
      //console.log(worksheet);
    });
  };

  useEffect(() => {
    let dimensionBoxes;

    let noOfDimensions = dimensions.length;

    if (noOfDimensions === 4) {
      dimensionBoxes = (
        <div className="w-full mt-4">
          <h1 className="text-lg text-gray-700">Select Dimensions</h1>
          <div className="flex flex-wrap">
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="parent"
                value={parent}
                onChange={onSelectParent}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text"
                value=""
                onClick={() => onHandleShowColorPicker()}
                style={{
                  backgroundColor: `${changeColor}`,
                  width: "40px",
                }}
              />

              {displayColorPicker && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker()}
                  />
                  <ChromePicker color={color} onChange={onChangeColorPicker} />
                </div>
              )}
            </div>
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="dimension1"
                value={dimension1}
                onChange={onSelectDimension1}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text2"
                value=""
                onClick={() => onHandleShowColorPicker2()}
                style={{
                  backgroundColor: `${changeColor2}`,
                  width: "40px",
                }}
              />

              {displayColorPicker2 && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker2()}
                  />
                  <ChromePicker
                    color={color2}
                    onChange={onChangeColorPicker2}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="dimension2"
                value={dimension2}
                onChange={onSelectDimension2}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text3"
                value=""
                onClick={() => onHandleShowColorPicker3()}
                style={{
                  backgroundColor: `${changeColor3}`,
                  width: "40px",
                }}
              />

              {displayColorPicker3 && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker3()}
                  />
                  <ChromePicker
                    color={color3}
                    onChange={onChangeColorPicker3}
                  />
                </div>
              )}
            </div>
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="dimension3"
                value={dimension3}
                onChange={onSelectDimension3}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text4"
                value=""
                onClick={() => onHandleShowColorPicker4()}
                style={{
                  backgroundColor: `${changeColor4}`,
                  width: "40px",
                }}
              />

              {displayColorPicker4 && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker4()}
                  />
                  <ChromePicker
                    color={color4}
                    onChange={onChangeColorPicker4}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (noOfDimensions === 3) {
      dimensionBoxes = (
        <div className="w-full mt-4">
          <h1 className="text-lg text-gray-700">Select Dimensions</h1>
          <div className="flex flex-wrap">
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="parent"
                value={parent}
                onChange={onSelectParent}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text"
                value=""
                onClick={() => onHandleShowColorPicker()}
                style={{
                  backgroundColor: `${changeColor}`,
                  width: "40px",
                }}
              />

              {displayColorPicker && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker()}
                  />
                  <ChromePicker color={color} onChange={onChangeColorPicker} />
                </div>
              )}
            </div>
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="dimension1"
                value={dimension1}
                onChange={onSelectDimension1}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text2"
                value=""
                onClick={() => onHandleShowColorPicker2()}
                style={{
                  backgroundColor: `${changeColor2}`,
                  width: "40px",
                }}
              />

              {displayColorPicker2 && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker2()}
                  />
                  <ChromePicker
                    color={color2}
                    onChange={onChangeColorPicker2}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="basis-1/2 p-1 flex">
              <input
                className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                name="dimension2"
                value={dimension2}
                onChange={onSelectDimension2}
                readOnly
              />

              <input
                className="form-control color-buttons"
                type="text"
                name="color-picker-text3"
                value=""
                onClick={() => onHandleShowColorPicker3()}
                style={{
                  backgroundColor: `${changeColor3}`,
                  width: "40px",
                }}
              />

              {displayColorPicker3 && (
                <div className="color-picker-palette">
                  <div
                    className="color-picker-cover"
                    onClick={() => onHandleCloseColorPicker3()}
                  />
                  <ChromePicker
                    color={color3}
                    onChange={onChangeColorPicker3}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (noOfDimensions === 2) {
      dimensionBoxes = "";
    } else if (noOfDimensions === 1) {
      dimensionBoxes = "";
    } else if (noOfDimensions === 0) {
      dimensionBoxes = "";
    }

    setDimensionTextBoxes(dimensionBoxes);
  }, [
    dimensions,
    changeColor,
    changeColor2,
    changeColor3,
    changeColor4,
    color,
    color2,
    color3,
    color4,
    dimension1,
    dimension2,
    dimension3,
    displayColorPicker,
    displayColorPicker2,
    displayColorPicker3,
    displayColorPicker4,
    parent,
  ]);

  useEffect(() => {
    let newJsonArray = [];

    let singleArray = [];

    _.map(worksheetData, (jt) => {
      _.map(jt, (newJt) => {
        singleArray.push(newJt._value);
      });
    });
    newJsonArray.push(singleArray);

    let finalJsonArray = _.chunk(newJsonArray[0], headers.length);

    finalJsonArray.unshift(headers);

    let metricsOptions = _.map(metrics, (metric) => {
      return <option value={metric}>{metric}</option>;
    });

    setMetricsOptions(metricsOptions);

    let dimension1Array;

    if (dimension1 === "") {
      //console.log("dimension1 not selected");
    } else {
      // console.log("dimension1 is selected");

      let dimension1Index = _.indexOf(headers, dimension1);
      dimension1Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension1Index];
      });
    }

    //console.log("dimension1Array", dimension1Array);

    let dimension2Array;

    if (dimension2 === "") {
      //console.log("dimension2 not selected");
    } else {
      //console.log("dimension2 is selected");

      let dimension2Index = _.indexOf(headers, dimension2);
      dimension2Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension2Index];
      });
    }

    //console.log("dimension2Array", dimension2Array);

    let dimension3Array;

    if (dimension3 === "") {
      //console.log("dimension3 not selected");
    } else {
      //console.log("dimension3 is selected");

      let dimension3Index = _.indexOf(headers, dimension3);
      dimension3Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension3Index];
      });
    }

    //console.log("dimension3Array", dimension3Array);

    let parentArray;

    if (parent === "") {
      //console.log("parent not selected");
    } else {
      //console.log("parent is selected");

      let parentIndex = _.indexOf(headers, parent);
      parentArray = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[parentIndex];
      });
    }

    setParentArray(parentArray);
    setDimension1Array(dimension1Array);
    setDimension2Array(dimension2Array);
    setDimension3Array(dimension3Array);
  }, [
    worksheetData,
    metricsOptions,
    headers,
    metrics,
    dimension1,
    dimension2,
    dimension3,
    parent,
  ]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const toggle3 = () => {
    setModal3(!modal3);
  };

  const onClickChart = () => {
    if (metric1 === "" && metric2 === "") {
      NotificationManager.error(
        "Please select at least one metric",
        "Required"
      );
    } else if (metric1 !== "" && metric2 === "") {
      setLoaded(!loaded);

      // setTimeout(() => {
      //   setRedirect(true);
      // }, 3000);

      // NotificationManager.success(
      //   "Sunburst chart is getting generated.",
      //   "Success"
      // );
    } else if (metric1 === "" && metric2 !== "") {
      setLoaded(!loaded);

      // setTimeout(() => {
      //   setRedirect(true);
      // }, 3000);

      // NotificationManager.success(
      //   "Sunburst chart is getting generated.",
      //   "Success"
      // );
    } else if (metric1 !== "" && metric2 !== "") {
      setLoaded(!loaded);

      // setTimeout(() => {
      //   setRedirect(true);
      // }, 3000);

      // NotificationManager.success(
      //   "Sunburst chart is getting generated.",
      //   "Success"
      // );
    }
    navigate("/chart", {
      state: {
        sheetName,
        parentArray,
        dimension1Array,
        dimension2Array,
        dimension3Array,
        dimensions,
        metric1,
        metric2,
        metric1Array,
        metric2Array,
        metrics,
        finalJsonArray,
        parent,
        dimension1,
        dimension2,
        dimension3,
        changeColor,
        changeColor2,
        changeColor3,
        changeColor4,
        newArrayOfData,
        unit1,
        unit2,
        saveSettings,
      },
    });
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const onHandleShowColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const onHandleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const onChangeColorPicker = (color) => {
    setColor(color.rgb);
    setChangeColor(color.hex);
  };

  const onHandleShowColorPicker2 = () => {
    setDisplayColorPicker2(true);
  };

  const onHandleCloseColorPicker2 = () => {
    setDisplayColorPicker2(false);
  };

  const onChangeColorPicker2 = (color) => {
    setColor2(color.rgb);
    setChangeColor2(color.hex);
  };

  const onHandleShowColorPicker3 = () => {
    setDisplayColorPicker3(true);
  };

  const onHandleCloseColorPicker3 = () => {
    setDisplayColorPicker3(false);
  };

  const onChangeColorPicker3 = (color) => {
    setColor3(color.rgb);
    setChangeColor3(color.hex);
  };

  const onHandleShowColorPicker4 = () => {
    setDisplayColorPicker4(true);
  };

  const onHandleCloseColorPicker4 = () => {
    setDisplayColorPicker4(false);
  };

  const onChangeColorPicker4 = (color) => {
    setColor4(color.rgb);
    setChangeColor4(color.hex);
  };

  const onSelectParent = (e) => {
    setParent(e.target.value);
  };

  const onSelectDimension1 = (e) => {
    setDimension1(e.target.value);
  };

  const onSelectDimension2 = (e) => {
    setDimension2(e.target.value);
  };

  const onSelectDimension3 = (e) => {
    setDimension3(e.target.value);
  };

  const onSelectMetric1 = (e) => {
    setMetric1(e.target.value);

    let metric1Array;

    if (metric1 === "") {
      //console.log("metric1 not selected");
    } else {
      //console.log("metric1 is selected");

      let metric1Index = _.indexOf(headers, e.target.value);
      metric1Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[metric1Index];
      });

      setMetric1Array(metric1Array);
    }
  };

  const onSelectMetric2 = (e) => {
    setMetric2(e.target.value);

    let metric2Array;

    if (metric2 === "") {
      // console.log("metric2 not selected");
    } else {
      // console.log("metric2 is selected");

      let metric2Index = _.indexOf(headers, e.target.value);
      metric2Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[metric2Index];
      });

      setMetric2Array(metric2Array);
    }
  };

  const onSaveSettings = (e) => {
    setSaveSettings(e.target.value);

    if (e.target.value === "Yes") {
      console.log("Yes");

      if (dimensions.length === 4) {
        tableau.extensions.settings.set("saveSettings", e.target.value);
        tableau.extensions.settings.set("sheetName", sheetName);
        tableau.extensions.settings.set("columns", JSON.stringify(columns));
        tableau.extensions.settings.set(
          "chartWorkSheetData",
          JSON.stringify(chartWorkSheetData)
        );
        
        tableau.extensions.settings.set("parent", parent);
        tableau.extensions.settings.set("dimension1", dimension1);
        tableau.extensions.settings.set("dimension2", dimension2);
        tableau.extensions.settings.set("dimension3", dimension3);
        tableau.extensions.settings.set(
          "dimensions",
          JSON.stringify(dimensions)
        );
        // tableau.extensions.settings.set(
        //   "metrics",
        //   JSON.stringify(metrics)
        // );
        tableau.extensions.settings.set("changeColor", changeColor);
        tableau.extensions.settings.set("changeColor2", changeColor2);
        tableau.extensions.settings.set("changeColor3", changeColor3);
        tableau.extensions.settings.set("changeColor4", changeColor4);
        tableau.extensions.settings.set("unit1", unit1);
        tableau.extensions.settings.set("unit2", unit2);
        tableau.extensions.settings.set("showChart", true);

        if (metrics.length === 2) {
          tableau.extensions.settings.set("metric1", metric1);
          tableau.extensions.settings.set("metric2", metric2);
        } else if (metrics.length === 1) {
          tableau.extensions.settings.set("metric1", metric1);
          tableau.extensions.settings.set("metric2", metric1);
        }


      } else if (dimensions.length === 3) {
        tableau.extensions.settings.set("saveSettings", e.target.value);
        tableau.extensions.settings.set("sheetName", sheetName);
        tableau.extensions.settings.set("columns", JSON.stringify(columns));
        tableau.extensions.settings.set(
          "chartWorkSheetData",
          JSON.stringify(chartWorkSheetData)
        );
        // tableau.extensions.settings.set("metric1", metric1);
        // tableau.extensions.settings.set("metric2", metric2);
        tableau.extensions.settings.set("parent", parent);
        tableau.extensions.settings.set("dimension1", dimension1);
        tableau.extensions.settings.set("dimension2", dimension2);
        // tableau.extensions.settings.set("dimension3", dimension3);
        tableau.extensions.settings.set(
          "dimensions",
          JSON.stringify(dimensions)
        );
        tableau.extensions.settings.set("changeColor", changeColor);
        tableau.extensions.settings.set("changeColor2", changeColor2);
        tableau.extensions.settings.set("changeColor3", changeColor3);
        tableau.extensions.settings.set("changeColor4", changeColor4);
        tableau.extensions.settings.set("unit1", unit1);
        tableau.extensions.settings.set("unit2", unit2);
        tableau.extensions.settings.set("showChart", true);

        if (metrics.length === 2) {
          tableau.extensions.settings.set("metric1", metric1);
          tableau.extensions.settings.set("metric2", metric2);
        } else if (metrics.length === 1) {
          tableau.extensions.settings.set("metric1", metric1);
          tableau.extensions.settings.set("metric2", metric1);
        } else {
          tableau.extensions.settings.set("metric1", metric1);
          tableau.extensions.settings.set("metric2", metric2);
        }
      }

      tableau.extensions.settings.saveAsync();
    } else {
      console.log("No");
    }
  };

  var options = {
    lines: 13,
    length: 10,
    width: 5,
    radius: 20,
    scale: 1.0,
    corners: 1,
    color: "#000",
    opacity: 0,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50%",
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "absolute",
  };

  //   if (redirect) {
  //     navigate('/chart', {
  //       state: {
  //           sheetName,
  //           parentArray,
  //           dimension1Array,
  //           dimension2Array,
  //           dimension3Array,
  //           dimensions,
  //           metric1,
  //           metric2,
  //           metric1Array,
  //           metric2Array,
  //           metrics,
  //           finalJsonArray,
  //           parent,
  //           dimension1,
  //           dimension2,
  //           dimension3,
  //           changeColor,
  //           changeColor2,
  //           changeColor3,
  //           changeColor4,
  //           newArrayOfData,
  //           unit1,
  //           unit2
  //       }
  // });
  //   }

  let displayChart;

  if (showChart) {
    // displayChart =
    //   <DisplayChart
    //     sheetName={sheetName}
    //     parentArray={parentArray}
    //     dimension1Array={dimension1Array}
    //     dimension2Array={dimension2Array}
    //     dimension3Array={dimension3Array}
    //     dimensions={dimensions}
    //     metric1={metric1}
    //     metric2={metric2}
    //     metric1Array={metric1Array}
    //     metric2Array={metric2Array}
    //     metrics={metrics}
    //     finalJsonArray={finalJsonArray}
    //     parent={parent}
    //     dimension1={dimension1}
    //     dimension2={dimension2}
    //     dimension3={dimension3}
    //     changeColor={changeColor}
    //     changeColor2={changeColor2}
    //     changeColor3={changeColor3}
    //     changeColor4={changeColor4}
    //     unit1={unit1}
    //     unit2={unit2}

    //   />

    displayChart =
      <GetDataForChart
        sheetName={sheetName}
        columns={columns}
        chartWorkSheetData={chartWorkSheetData}
        metric1={metric1}
        metric2={metric2}
        parent={parent}
        dimension1={dimension1}
        dimension2={dimension2}
        dimension3={dimension3}
        changeColor={changeColor}
        changeColor2={changeColor2}
        changeColor3={changeColor3}
        changeColor4={changeColor4}
        unit1={unit1}
        unit2={unit2}

      />

    // displayChart = (
    //   <div className="  md:w-2/3 lg:w-2/3 sm:w-full ml-auto mr-auto mt-2">
    //     <Header />
    //     <Loader loaded={loaded} options={options} className="spinner">
    //       <Modal isOpen={modal} toggle={toggle}>
    //         <ModalHeader toggle={toggle}>
    //           <h3>Error!</h3>
    //         </ModalHeader>
    //         <ModalBody>
    //           When four dimensions are in use, the first dimension{" "}
    //           <strong>{parent}</strong> should have a single value. Please
    //           remove or replace this field from the sheet and try again.
    //         </ModalBody>
    //         <ModalFooter>
    //           <Button color="primary" onClick={toggle}>
    //             Ok
    //           </Button>
    //         </ModalFooter>
    //       </Modal>

    //       <Modal isOpen={modal2} toggle={toggle2}>
    //         <ModalHeader toggle={toggle2}>
    //           <h3>Error!</h3>
    //         </ModalHeader>
    //         <ModalBody>
    //           The chart doesn't support more than four dimensions. Would you
    //           please remove a dimension from the sheet and try again
    //         </ModalBody>
    //         <ModalFooter>
    //           <Button color="primary" onClick={toggle2}>
    //             Ok
    //           </Button>
    //         </ModalFooter>
    //       </Modal>

    //       <Modal isOpen={modal3} toggle={toggle3}>
    //         <ModalHeader toggle={toggle3}>
    //           <h3>Required!</h3>
    //         </ModalHeader>
    //         <ModalBody>A minimum of three dimensions are required.</ModalBody>
    //         <ModalFooter>
    //           <Button color="primary" onClick={toggle3}>
    //             Ok
    //           </Button>
    //         </ModalFooter>
    //       </Modal>
    //       <div className="flex mt-5">
    //         <div className=" basis-1/2 border-r-neutral-200 border-r p-2">
    //           <div className="w-full">
                
    //             <h1 className="text-lg text-gray-700">Configure Data Source</h1>
    //             <select
    //               className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
    //               name="sheet"
    //               value={sheet}
    //               onChange={onSelectSheet}
    //             >
    //               <option>Select Data Sheet</option>

    //               {dashboardSheets}
    //             </select>
    //           </div>
    //           {dimensionTextBoxes}
    //           <div className="w-full mt-4" style={{ display: showDiv }}>
    //             <h1 className="text-lg text-gray-700">Select Metric(s)</h1>
    //             <div className="flex flex-wrap">
    //               <div className="basis-1/2 p-1">
    //                 <select
    //                   className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
    //                   name="metric1"
    //                   value={metric1}
    //                   onChange={onSelectMetric1}
    //                 >
    //                   <option>Metric 1</option>
    //                   {metricsOptions}
    //                 </select>
    //               </div>
    //               <div className="basis-1/2 p-1">
    //                 <select
    //                   className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
    //                   name="metric2"
    //                   value={metric2}
    //                   onChange={onSelectMetric2}
    //                 >
    //                   <option>Metric 2</option>
    //                   {metricsOptions}
    //                 </select>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="w-full mt-4" style={{ display: showDiv }}>
    //             <h1 className="text-lg text-gray-700">
    //               Would you like to save the settings?
    //             </h1>
    //             <div className="flex">
    //               <select
    //                 className=" w-1/4 border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
    //                 name="saveSettings"
    //                 value={saveSettings}
    //                 onChange={onSaveSettings}
    //               >
    //                 <option>Select</option>
    //                 <option value={`Yes`}>Yes</option>
    //                 <option value={`No`}>No</option>
    //               </select>
    //               <a href="/#" id="TooltipExample" className="ml-4">
    //                 <FaInfoCircle />
    //               </a>
    //               <Tooltip
    //                 isOpen={tooltipOpen}
    //                 target="TooltipExample"
    //                 toggle={toggleTooltip}
    //               >
    //                 Not saving the settings will reset the sunburst chart after
    //                 every refresh.
    //               </Tooltip>
    //             </div>
    //           </div>
    //           <div
    //             className="w-full mt-3 text-right"
    //             style={{ display: showDiv }}
    //           >
    //             <img
    //               src={CREATEBTN}
    //               alt=""
    //               onClick={onClickChart}
    //               className="createBtn"
    //             />
    //           </div>
    //        <div>
    //        sheetName={sheetName} <br/>
    //        columns={JSON.stringify(columns)} <br/>
    //        chartWorkSheetData={JSON.stringify(chartWorkSheetData)} <br/>
    //            metric1={metric1} <br/>
    //            metric2={metric2} <br/>
    //            parent={parent} <br/>
    //            dimension1={dimension1} <br/>
    //            dimension2={dimension2} <br/>
    //            dimension3={dimension3} <br/>
    //            changeColor={changeColor} <br/>
    //            changeColor2={changeColor2} <br/>
    //            changeColor3={changeColor3} <br/>
    //            changeColor4={changeColor4} <br/>
    //            unit1={unit1} <br/>
    //            unit2={unit2} <br/>
    //            saveSettings={saveSettings}
    //        </div>
    //         </div>
    //         <div className=" basis-1/2 p-2">
    //           <h3 className=" text-2xl mb-3">Instructions</h3>

    //           <p className=" leading-relaxed text-sm">
    //             Please have a sheet created with the required fields and load it
    //             on your dashboard. Use the floating option and reduce the size
    //             as small as possible if you don't intend to show it to your
    //             viewers.
    //           </p>
    //           <p className=" leading-relaxed text-sm">
    //             Select the sheet mentioned above in the first dropdown (Select
    //             Sheet). According to the hierarchical order, up to four
    //             dimensions will be selected. 
    //           </p>
    //           <p className=" leading-relaxed text-sm">
    //             The ideal number of dimensions that we can use is three. When
    //             four dimensions are used, the first dimension (Category) should
    //             only have a single element. 
    //           </p>
    //           <p className="leading-relaxed text-sm">
    //             A minimum of one metric is required to build the chart. Second
    //             metric selection is optional; selecting this will allow the user
    //             to toggle between these two metrics.
    //           </p>
    //           <p className=" leading-relaxed text-sm">
    //             AVOID USING fields with negative values. Fields with negative
    //             values make the sunburst irregular. 
    //           </p>
    //         </div>
    //       </div>
    //       <div className=" text-center p-2  mt-2">
    //         <h2 className="text-lg">Talk to us:</h2>
    //         <a href="mailto:engineering@decisionfoundry.com">
    //           engineering@decisionfoundry.com
    //         </a>{" "}
    //         |{" "}
    //         <a
    //           href="https://www.decisionfoundry.com"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           www.decisionfoundry.com
    //         </a>
    //       </div>
    //     </Loader>

    //     <NotificationContainer />
    //   </div>
    // );

   
  } else {
    displayChart = (
      <div className="  md:w-2/3 lg:w-2/3 sm:w-full ml-auto mr-auto mt-2">
        <Header />
        <Loader loaded={loaded} options={options} className="spinner">
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <h3>Error!</h3>
            </ModalHeader>
            <ModalBody>
              When four dimensions are in use, the first dimension{" "}
              <strong>{parent}</strong> should have a single value. Please
              remove or replace this field from the sheet and try again.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader toggle={toggle2}>
              <h3>Error!</h3>
            </ModalHeader>
            <ModalBody>
              The chart doesn't support more than four dimensions. Would you
              please remove a dimension from the sheet and try again
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle2}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modal3} toggle={toggle3}>
            <ModalHeader toggle={toggle3}>
              <h3>Required!</h3>
            </ModalHeader>
            <ModalBody>A minimum of three dimensions are required.</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle3}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
          <div className="flex mt-5">
            <div className=" basis-1/2 border-r-neutral-200 border-r p-2">
              <div className="w-full">
                
                <h1 className="text-lg text-gray-700">Configure Data Source</h1>
                <select
                  className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                  name="sheet"
                  value={sheet}
                  onChange={onSelectSheet}
                >
                  <option>Select Data Sheet</option>

                  {dashboardSheets}
                </select>
              </div>
              {dimensionTextBoxes}
              <div className="w-full mt-4" style={{ display: showDiv }}>
                <h1 className="text-lg text-gray-700">Select Metric(s)</h1>
                <div className="flex flex-wrap">
                  <div className="basis-1/2 p-1">
                    <select
                      className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                      name="metric1"
                      value={metric1}
                      onChange={onSelectMetric1}
                    >
                      <option>Metric 1</option>
                      {metricsOptions}
                    </select>
                  </div>
                  <div className="basis-1/2 p-1">
                    <select
                      className=" w-full border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                      name="metric2"
                      value={metric2}
                      onChange={onSelectMetric2}
                    >
                      <option>Metric 2</option>
                      {metricsOptions}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full mt-4" style={{ display: showDiv }}>
                <h1 className="text-lg text-gray-700">
                  Would you like to save the settings?
                </h1>
                <div className="flex">
                  <select
                    className=" w-1/4 border border-cyan-900 rounded-xl text-md p-1 bg-gradient-to-r bg-slate-100 cursor-pointer"
                    name="saveSettings"
                    value={saveSettings}
                    onChange={onSaveSettings}
                  >
                    <option>Select</option>
                    <option value={`Yes`}>Yes</option>
                    <option value={`No`}>No</option>
                  </select>
                  <a href="/#" id="TooltipExample" className="ml-4">
                    <FaInfoCircle />
                  </a>
                  <Tooltip
                    isOpen={tooltipOpen}
                    target="TooltipExample"
                    toggle={toggleTooltip}
                  >
                    Not saving the settings will reset the sunburst chart after
                    every refresh.
                  </Tooltip>
                </div>
              </div>
              <div
                className="w-full mt-3 text-right"
                style={{ display: showDiv }}
              >
                <img
                  src={CREATEBTN}
                  alt=""
                  onClick={onClickChart}
                  className="createBtn"
                />
              </div>
             
          
            </div>
            <div className=" basis-1/2 p-2">
              <h3 className=" text-2xl mb-3">Instructions</h3>

              <p className=" leading-relaxed text-sm">
                Please have a sheet created with the required fields and load it
                on your dashboard. Use the floating option and reduce the size
                as small as possible if you don't intend to show it to your
                viewers.
              </p>
              <p className=" leading-relaxed text-sm">
                Select the sheet mentioned above in the first dropdown (Select
                Sheet). According to the hierarchical order, up to four
                dimensions will be selected. 
              </p>
              <p className=" leading-relaxed text-sm">
                The ideal number of dimensions that we can use is three. When
                four dimensions are used, the first dimension (Category) should
                only have a single element. 
              </p>
              <p className="leading-relaxed text-sm">
                A minimum of one metric is required to build the chart. Second
                metric selection is optional; selecting this will allow the user
                to toggle between these two metrics.
              </p>
              <p className=" leading-relaxed text-sm">
                AVOID USING fields with negative values. Fields with negative
                values make the sunburst irregular. 
              </p>
            </div>
          </div>
          <div className=" text-center p-2  mt-2">
            <h2 className="text-lg">Talk to us:</h2>
            <a href="mailto:engineering@decisionfoundry.com">
              engineering@decisionfoundry.com
            </a>{" "}
            |{" "}
            <a
              href="https://www.decisionfoundry.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.decisionfoundry.com
            </a>
          </div>
        </Loader>

        <NotificationContainer />
      </div>
    );
  }

  return <div>{displayChart}</div>;
};

export default GetStarted;
