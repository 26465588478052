import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const GetDataForChart = (props) => {
  const navigate = useNavigate();
  // const [sheetName, setSheetName] = useState(props.sheetName);
  // const [columns, setColumns] = useState(JSON.parse(props.columns));
  // const [chartWorkSheetData, setChartWorkSheetData] = useState(
  //   JSON.parse(props.chartWorkSheetData)
  // );
  // const [metric1, setMetric1] = useState(props.metric1);
  // const [metric2, setMetric2] = useState(props.metric2);
  // const [parent, setParent] = useState(props.parent);
  // const [dimension1, setDimension1] = useState(props.dimension1);
  // const [dimension2, setDimension2] = useState(props.dimension2);
  // const [dimension3, setDimension3] = useState(props.dimension3);
  // const [changeColor, setChangeColor] = useState(props.changeColor);
  // const [changeColor2, setChangeColor2] = useState(props.changeColor2);
  // const [changeColor3, setChangeColor3] = useState(props.changeColor3);
  // const [changeColor4, setChangeColor4] = useState(props.changeColor4);
  // const [unit1, seUunit1] = useState(props.unit1);
  // const [unit2, setUnit2] = useState(props.unit2);
  // const [parentArray, setParentArray] = useState([]);
  // const [dimension1Array, setDimension1Array] = useState([]);
  // const [dimension2Array, setDimension2Array] = useState([]);
  // const [dimension3Array, setDimension3Array] = useState([]);
  // const [headers, setHeaders] = useState([]);
  // const [dimensions, setDimensions] = useState([]);
  // const [metrics, setMetrics] = useState([]);
  // const [finalJsonArray, setFinalJsonArray] = useState([]);
  // const [newArrayOfData, setNewArrayOfData] = useState([]);
  // const [metric1Array, setMetric1Array] = useState([]);
  // const [metric2Array, setMetric2Array] = useState([]);

  useEffect(() => {
    let sheetName = props.sheetName;
    let columns = JSON.parse(props.columns);
    let chartWorkSheetData = JSON.parse(props.chartWorkSheetData);
    let metric1 = props.metric1;
    let metric2 = props.metric2;
    let parent = props.parent;
    let dimension1 = props.dimension1;
    let dimension2 = props.dimension2;
    let dimension3 = props.dimension3;
    let changeColor = props.changeColor;
    let changeColor2 = props.changeColor2;
    let changeColor3 = props.changeColor3;
    let changeColor4 = props.changeColor4;
    let unit1 = props.unit1;
    let unit2 = props.unit2;

    // First get Headers, dimensions & metrics

    let headers = [];
    let dimensions = [];
    let metrics = [];

    _.map(columns, (col) => {
      if (col._dataType === "float" || col._dataType === "int") {
        metrics.push(col._fieldName);
      } else {
        dimensions.push(col._fieldName);
      }
      headers.push(col._fieldName);
    });

    // setHeaders(headers);
    // setDimensions(dimensions);
    // setMetrics(metrics);

    // Get newArrayOfData

    let newHeaders = [];
    let headerIndex = [];
    let newArrayOfData = [];

    _.map(columns, (dt) => {
      // console.log(dt);

      if (dt._dataType === "string") {
      } else {
        // console.log(dt._fieldName);
        newHeaders.push(dt._fieldName);
        headerIndex.push(dt._index);
      }
    });

    _.map(chartWorkSheetData, (dt) => {
      // console.log(dt[headerIndex[headerIndex.length-(headerIndex.length)]])

      //var dataObject = [];

      _.map(headerIndex, (hi, index) => {
        // console.log(dt[hi]);
        let unit;
        let currencies = ["£", "¥", "₹", "€", "$"];
        let currencyArray = [];

        _.map(currencies, (dt1) => {
          var currencyIndex = _.indexOf(dt[hi]._formattedValue, dt1);

          //  console.log(currencyIndex)

          currencyArray.push(currencyIndex);
        });

        //console.log("CurrencyArray",currencyArray)

        var metricPercentSearch = _.indexOf(dt[hi]._formattedValue, "%");

        var findIndexofZero = _.indexOf(currencyArray, 0);

        if (findIndexofZero >= 0) {
          unit = currencies[findIndexofZero];
        } else if (metricPercentSearch >= 0) {
          unit = "%";
        } else {
          unit = "";
        }

        var newObject = {
          metric: newHeaders[index],
          value: dt[hi]._value,
          unit: unit,
        };

        // console.log(newObject);

        newArrayOfData.push(newObject);
      });
    });

    // setNewArrayOfData(newArrayOfData);

    //Get finalJsonArray

    let newJsonArray = [];

    let singleArray = [];

    _.map(chartWorkSheetData, (jt) => {
      _.map(jt, (newJt) => {
        // console.log("Type Of", typeof newJt._value);
        singleArray.push(newJt._value);
      });
    });
    newJsonArray.push(singleArray);

    let finalJsonArray = _.chunk(newJsonArray[0], headers.length);

    finalJsonArray.unshift(headers);

    // setFinalJsonArray(finalJsonArray);

    //Get Dimension1Array

    let dimension1Array;

    // console.log("dimension1 is selected");

    if (dimension1 === "") {
    } else {
      let dimension1Index = _.indexOf(headers, dimension1);
      dimension1Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension1Index];
      });

      //setDimension1Array(dimension1Array);
    }

    //Get Dimension2Array

    let dimension2Array;

    // console.log("dimension1 is selected");

    if (dimension2 === "") {
    } else {
      let dimension2Index = _.indexOf(headers, dimension2);
      dimension2Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension2Index];
      });

      //setDimension2Array(dimension2Array);
    }

    //Get Dimension3Array

    let dimension3Array;

    // console.log("dimension1 is selected");

    if (dimension3 === "") {
    } else {
      let dimension3Index = _.indexOf(headers, dimension3);
      dimension3Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[dimension3Index];
      });

      // setDimension3Array(dimension3Array);
    }

    //Get parentArray
    let parentArray;
    if (parent === "") {
    } else {
      let parentIndex = _.indexOf(headers, parent);
      parentArray = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[parentIndex];
      });

      //setParentArray(parentArray);
    }

    // Get Metric1Array
    let metric1Array;
    if (metric1 === "") {
    } else {
      let metric1Index = _.indexOf(headers, metric1);
      metric1Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[metric1Index];
      });

      // setMetric1Array(metric1Array);
    }

    let metric2Array;
    // Get Metric2Array
    if (metric1 === "") {
    } else {
      let metric2Index = _.indexOf(headers, metric2);
      metric2Array = _.flatMap(finalJsonArray, (oneDArray) => {
        return oneDArray[metric2Index];
      });

      // setMetric2Array(metric2Array);
    }

    navigate("/viewChart", {
      state: {
        sheetName,
        parentArray,
        metric1Array,
        metric2Array,
        dimension1Array,
        dimension2Array,
        dimension3Array,
        dimensions,
        metric1,
        metric2,

        metrics,
        finalJsonArray,
        parent,
        dimension1,
        dimension2,
        dimension3,
        changeColor,
        changeColor2,
        changeColor3,
        changeColor4,
        newArrayOfData,
        unit1,
        unit2,
      },
    });
  }, [
    props.changeColor,
    props.changeColor2,
    props.changeColor3,
    props.changeColor4,
    props.chartWorkSheetData,
    props.columns,
    props.dimension1,
    props.dimension2,
    props.dimension3,
    props.metric1,
    props.metric2,
    props.parent,
    props.sheetName,
    props.unit1,
    props.unit2,
    navigate,
    // parentArray,
    // sheetName,
    // unit1,
    // unit2,
  ]);

  return <div></div>;
};

export default GetDataForChart;
