import React from 'react'

import LOGO from "../dflogo_black.png";
import ICON from "../SB-chart-logo.png"


const Header = () => {
  return (
    <div className="flex flex-nowrap bg-slate-200 shadow-md p-2">
       <div className="basis-3/4 flex"><img src={ICON} alt="Decision Foundry" /> <h1 className="md:text-4xl sm:text-2xl lg:text-4xl text pt-2 ml-2"> Dynamic Sunburst Chart</h1></div>
       <div className="basis-1/4 right-2"><img src={LOGO} alt="Decision Foundry" className=" mt-3" /></div>
    </div>
  )
}

export default Header