import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import _ from "lodash";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useLocation } from 'react-router-dom'

HighchartsSunburst(Highcharts);

const Chart = () => {
  const location = useLocation();
  const [metric, setMetric] = useState();
  //const [selectedMetricArray, setSelectedMetricArray] = useState([]);
  const [data, setData] = useState([]);
  // const [dimension1ArrayHeader, setDimension1ArrayHeader] = useState();
  // const [dimension2ArrayHeader, setDimension2ArrayHeader] = useState();
  // const [dimension3ArrayHeader, setDimension3ArrayHeader] = useState();
  const [chartTooltip, setChartTooltip] = useState(
    "<b>{point.name}</b>: <b>{point.value}</b>"
  );
  //const [level, setLevel] = useState(1);

  // const [dimensions, setDimensions] = useState(location.state.dimensions)
  // const [parentArray, setParentArray] = useState(location.state.parentArray)
  // const [dimension1Array, setDimension1Array] = useState(location.state.dimension1Array)
  // const [dimension2Array, setDimension2Array] = useState(location.state.dimension2Array)
  // const [dimension3Array, setDimension3Array] = useState(location.state.dimension3Array)
  


  const convertToJSON = (array) => {
    var objArray = [];
    for (var i = 1; i < array.length; i++) {
      objArray[i - 1] = {};
      for (var k = 0; k < array[0].length && k < array[i].length; k++) {
        var key = array[0][k];
        objArray[i - 1][key] = array[i][k];
      }
    }

    return objArray;
  };

  useEffect(() => {
    let dimensions =location.state.dimensions;
   // let sheetName =location.state.sheetName;
    let parentArray =location.state.parentArray;
    let dimension1Array =location.state.dimension1Array;
   let dimension2Array =location.state.dimension2Array;
   let dimension3Array =location.state.dimension3Array;
    let newArrayOfData =location.state.newArrayOfData;
    let metric1 =location.state.metric1;
    //  let metric2 =location.state.metric2;
    let metric1Array =location.state.metric1Array;
    let metric2Array =location.state.metric2Array;
    //  let metrics =location.state.metrics;
    let finalJsonArray =location.state.finalJsonArray;

    console.log(location.state.sheetName);
    console.log("Dimension1array",location.state.dimension1Array);
    console.log("Dimension2array",location.state.dimension2Array);
    console.log("Dimension3array",location.state.dimension3Array);
    console.log("parentArray",location.state.parentArray);
    console.log("Metric1",location.state.metric1);
    console.log("Metric2",location.state.metric2);
    console.log("Metric1Array",location.state.metric1Array);
    console.log("Metric2Array",location.state.metric2Array);
    console.log("Metrics",location.state.metrics);
    console.log("finalJsonArray",location.state.finalJsonArray);
    console.log("Dimension1",location.state.dimension1);
    console.log("Dimension2",location.state.dimension2);
    console.log("Dimension3",location.state.dimension3);
    console.log("Parent",location.state.parent);

    
    //setLevel(1)

    // var metric1 = "SUM(Sales)";

    let selectedMetricArray;

    var filterNewArray = _.filter(newArrayOfData, ["metric", metric1]);

    console.log(filterNewArray);

    var selectedUnit = filterNewArray[0].unit;

    if (metric1Array[0] === undefined) {
      if (selectedUnit === "%") {
        setChartTooltip(
          `<b>{point.name}</b>: <b>{point.value}${selectedUnit}</b>`
        );
      } else {
        setChartTooltip(
          `<b>{point.name}</b>: <b>${selectedUnit}{point.value}</b>`
        );
      }
    } else if (metric2Array[0] === undefined) {
      if (selectedUnit === "%") {
        setChartTooltip(
          `<b>{point.name}</b>: <b>{point.value}${selectedUnit}</b>`
        );
      } else {
        setChartTooltip(
          `<b>{point.name}</b>: <b>${selectedUnit}{point.value}</b>`
        );
      }
    }

    let mainJson = convertToJSON(finalJsonArray);

    let data;

    if (metric1Array === undefined) {
      selectedMetricArray = metric2Array;
    } else if (metric2Array === undefined) {
      selectedMetricArray = metric1Array;
    } else {
      selectedMetricArray = metric1Array;
    }

    selectedMetricArray.shift();

    //let parent1Array = [];
    let parentObject = [];
    let child1Array = [];
    let child2Array = [];
    let child3Array = [];

    let newDimension1Array;
    //let newDimension2Array;
    //  let newDimension3Array;

    parentArray.shift();
    dimension1Array.shift();
    dimension2Array.shift();
    dimension3Array.shift();

    console.log("mainJson", mainJson);

    if (dimensions.length === 4) {
      let parentArrayUniqe = _.uniq(parentArray);

      if (parentArrayUniqe.length === 1) {
        // parent1Array = _.uniq(parentArray);
        parentObject = [
          {
            id: "0.0",
            parent: "",
            // name: parentArrayUniqe[0],
            name: "Total",
          },
        ];

        let dimension1IDs = [];

        if (dimension1Array.length === 0) {
        } else {
          //Remove first element from array

          newDimension1Array = _.uniq(dimension1Array);

          console.log("newDimension1Array:", newDimension1Array);

          for (let i = 0; i < newDimension1Array.length; i++) {
            let singleId = _.uniqueId("1.");

            dimension1IDs.push(singleId);
          }

          for (let i = 0; i < dimension1IDs.length; i++) {
            let dim1Object = {
              id: dimension1IDs[i],
              parent: "0.0",
              name: newDimension1Array[i],
            };

            child1Array.push(dim1Object);
          }
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          //console.log(dimensions[1])

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            console.log(fiteredJson);

            //console.log(fiteredJson);
            //let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              // console.log(fObject);

              dimen2Json.push(fObject);
            });
          }

          let uniqDimen2Json = _.uniqWith(dimen2Json, _.isEqual);

          console.log(uniqDimen2Json);

          _.map(uniqDimen2Json, (dimen1) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            // let dsdsd = { [dimensions[1]]: dimen1[dimensions[1]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[1]],
            });

            //console.log(parentFilter[0].id);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[2]],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        if (dimension3Array.length === 0) {
        } else {
          let dimen3Json = [];

          for (let i = 0; i < dimension3Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
              [dimensions[3]]: dimension3Array[i],
            });

            //console.log(fiteredJson);

            // let chunkDimen2Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
                [dimensions[3]]: filJson[dimensions[3]],
              };

              //console.log(fObject);

              dimen3Json.push(fObject);
            });
          }

          // console.log(dimen2Json.length);

          let newDim3Array = [];

          _.map(dimen3Json, (dim1, index) => {
            let parentFilter = _.filter(child1Array, {
              name: dim1[dimensions[1]],
            });

            // console.log(parentFilter[0].id);

            let filterDim1 = _.filter(child2Array, {
              parent: parentFilter[0].id,
              name: dim1[dimensions[2]],
            });

            // console.log("filterDim1",filterDim1[0].id);

            let singleDimen1Id = _.uniqueId("3.");

            let dm1Object = {
              id: singleDimen1Id,
              //[dimensions[0]]:parentFilter[0].id,
              parent: filterDim1[0].id,
              name: dim1[dimensions[3]],
              value: selectedMetricArray[index],
            };

            //  console.log(dm1Object);

            newDim3Array.push(dm1Object);
          });

          // console.log(newDim1Array)

          child3Array = newDim3Array;
        }

        data = [
          ...parentObject,
          ...child1Array,
          ...child2Array,
          ...child3Array,
        ];
      } else {
        NotificationManager.error(
          "First Dimension should have only one value.",
          `Error ${dimensions.length} dimensions`
        );
      }
    } else if (dimensions.length === 3) {
      console.log("Dimensions are only 3");

      let parentArrayUniqe = _.uniq(parentArray);

      if (parentArrayUniqe.length === 1) {
        let parentObject = [
          {
            id: "0.0",
            parent: "",
            // name: parentArrayUniqe[0],
            name: "Total",
          },
        ];

        let child1Array = [];
        let child2Array = [];

        let dimension1IDs = [];

        if (dimension1Array.length === 0) {
        } else {
          //Remove first element from array
          dimension1Array.shift();

          newDimension1Array = _.uniq(dimension1Array);

          //console.log("newDimension1Array:", newDimension1Array);

          for (let i = 0; i < newDimension1Array.length; i++) {
            let singleId = _.uniqueId("1.");

            dimension1IDs.push(singleId);
          }

          for (let i = 0; i < dimension1IDs.length; i++) {
            let dim1Object = {
              id: dimension1IDs[i],
              parent: "0.0",
              name: newDimension1Array[i],
            };

            child1Array.push(dim1Object);
          }
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          //console.log(dimensions[1])

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            console.log(fiteredJson);

            //console.log(fiteredJson);
            //let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              console.log(fObject);

              dimen2Json.push(fObject);
            });
          }
          //console.log(dimen2Json);

          let uniqDimen2Json = _.uniqWith(dimen2Json, _.isEqual);
          console.log(uniqDimen2Json);

          _.map(uniqDimen2Json, (dimen1, index) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            //let dsdsd = { [dimensions[1]]: dimen1[dimensions[1]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[1]],
            });

            //console.log(parentFilter[0].id);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[2]],
              value: selectedMetricArray[index],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        data = [...parentObject, ...child1Array, ...child2Array];
      } else {
        let parentObject = [
          {
            id: "0.0",
            parent: "",
            // name: parentArrayUniqe[0],
            name: "Total",
          },
        ];

        let child1Array = [];
        let child2Array = [];
        let child3Array = [];

        if (parentArray.length === 0) {
        } else {
          let parentIDs = [];

          let parentArrayUniqe = _.uniq(parentArray);

          for (let i = 0; i < parentArrayUniqe.length; i++) {
            let singleParentId = _.uniqueId("1.");

            parentIDs.push(singleParentId);
          }

          //console.log("parentIDs:",parentIDs);

          // let parentObject = [];

          for (let i = 0; i < parentIDs.length; i++) {
            let pObject = {
              id: parentIDs[i],
              parent: "0.0",
              name: parentArrayUniqe[i],
            };

            console.log("pObject", pObject);

            child1Array.push(pObject);
          }
        }

        console.log("child1Array:", child1Array);

        if (dimension1Array.length === 0) {
        } else {
          // console.log(dimension1ArrayUnique)

          let dimen1Json = [];

          for (let i = 0; i < dimension1Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
            });

            //console.log(fiteredJson);
            // let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
              };

              // console.log(fObject);

              dimen1Json.push(fObject);
            });
          }

          // console.log(_.uniqWith(dimen1Json, _.isEqual));

          let uniqDimen1Json = _.uniqWith(dimen1Json, _.isEqual);

          _.map(uniqDimen1Json, (dimen1) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            // let dsdsd = { [dimensions[0]]: dimen1[dimensions[0]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[0]],
            });

            console.log(parentFilter);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[1]],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            //console.log(fiteredJson);

            // let chunkDimen2Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              //console.log(fObject);

              dimen2Json.push(fObject);
            });
          }

          // console.log(dimen2Json.length);

          let newDim1Array = [];

          _.map(dimen2Json, (dim1, index) => {
            let parentFilter = _.filter(child1Array, {
              name: dim1[dimensions[0]],
            });

            // console.log(parentFilter[0].id);

            let filterDim1 = _.filter(child2Array, {
              parent: parentFilter[0].id,
              name: dim1[dimensions[1]],
            });

            // console.log("filterDim1",filterDim1[0].id);

            let singleDimen1Id = _.uniqueId("3.");

            let dm1Object = {
              id: singleDimen1Id,
              //[dimensions[0]]:parentFilter[0].id,
              parent: filterDim1[0].id,
              name: dim1[dimensions[2]],
              value: selectedMetricArray[index],
            };

            //  console.log(dm1Object);

            newDim1Array.push(dm1Object);
          });

          // console.log(newDim1Array)

          child3Array = newDim1Array;
        }

        data = [
          ...parentObject,
          ...child1Array,
          ...child2Array,
          ...child3Array,
        ];
      }
    } else if (dimensions.length === 2) {
    }

    console.log("Data:", data);

    setData(data);
  }, [
   location.state.dimension1Array,
   location.state.dimension2Array,
   location.state.dimension3Array,
   location.state.dimensions,
   location.state.finalJsonArray,
   location.state.metric1Array,
   location.state.metric2Array,
   location.state.parentArray,
   location.state.sheetName,
   location.state.metric1,
   location.state.metrics,
   location.state.newArrayOfData,
   location.state.dimension1,
   location.state.dimension2,
   location.state.dimension3,
   location.state.metric2,
   location.state.parent
  ]);

  const onSelectMetric = (e) => {
    let metricValue = e.target.value;

    let metrics =location.state.metrics;
    let selectedMetricArray;
    // let dimension1ArrayHeader = state.dimension1ArrayHeader;
    // let dimension2ArrayHeader = state.dimension2ArrayHeader;
    // let dimension3ArrayHeader = state.dimension3ArrayHeader;

    console.log("Value Selected:", metricValue);
    console.log("Value Selected Index:", e.target.selectedIndex);

    let unit1 =location.state.unit1;
    let unit2 =location.state.unit2;

    let currencies = ["£", "¥", "₹", "€", "$"];
    let indexOfCurrency1 = _.indexOf(currencies, unit1);
    let indexOfCurrency2 = _.indexOf(currencies, unit2);

    let tooltip;

    if (e.target.selectedIndex === 0) {
      if (unit1 === currencies[indexOfCurrency1]) {
        tooltip = "<b>{point.name}</b>: <b>" + unit1 + "{point.value}</b>";
      } else if (unit1 === "%") {
        tooltip = "<b>{point.name}</b>: <b>{point.value}" + unit1 + "</b>";
      } else if (unit1 === "") {
        tooltip = "<b>{point.name}</b>: <b>{point.value}</b>";
      }
    } else {
      if (unit2 === currencies[indexOfCurrency2]) {
        tooltip = "<b>{point.name}</b>: <b>" + unit2 + "{point.value}</b>";
      } else if (unit2 === "%") {
        tooltip = "<b>{point.name}</b>: <b>{point.value}" + unit2 + "</b>";
      } else if (unit2 === "") {
        tooltip = "<b>{point.name}</b>: <b>{point.value}</b>";
      }
    }

    setChartTooltip(tooltip);

    if (metrics[0] === metricValue) {
      console.log("selected ", metricValue);
      selectedMetricArray =location.state.metric1Array;
    } else if (metrics[1] === metricValue) {
      console.log("selected ", metricValue);
      selectedMetricArray =location.state.metric2Array;
    } else {
      selectedMetricArray =location.state.metric1Array;
    }

    console.log("selectedMetricArray", selectedMetricArray);

    selectedMetricArray.shift();

     // let sheetName =location.state.sheetName;
    let parentArray =location.state.parentArray;
    let dimension1Array =location.state.dimension1Array;
    let dimension2Array =location.state.dimension2Array;
    let dimension3Array =location.state.dimension3Array;
    let dimensions =location.state.dimensions;
    // let sheetName =location.state.sheetName;

    //  let metric1Array =location.state.metric1Array;
    //  let metric2Array =location.state.metric2Array;
    // let metrics =location.state.metrics;
    let finalJsonArray =location.state.finalJsonArray;

    let mainJson = convertToJSON(finalJsonArray);

    let data;

    //let selectedMetricArray = state.selectedMetricArray;

    console.log("mainJson", mainJson);

    //let parent1Array = [];
    let parentObject = [];
    let child1Array = [];
    let child2Array = [];
    let child3Array = [];

    let newDimension1Array;
    // let newDimension2Array;
    //  let newDimension3Array;

    // parentArray.shift();
    // dimension1Array.shift();
    // dimension2Array.shift();
    //dimension3Array.shift();

    if (dimensions.length === 4) {
      let parentArrayUniqe = _.uniq(parentArray);

      if (parentArrayUniqe.length === 1) {
        // parent1Array = _.uniq(parentArray);
        parentObject = [
          {
            id: "0.0",
            parent: "",
            name: "Total",
          },
        ];

        let dimension1IDs = [];

        if (dimension1Array.length === 0) {
        } else {
          //Remove first element from array

          newDimension1Array = _.uniq(dimension1Array);

          console.log("newDimension1Array:", newDimension1Array);

          for (let i = 0; i < newDimension1Array.length; i++) {
            let singleId = _.uniqueId("1.");

            dimension1IDs.push(singleId);
          }

          for (let i = 0; i < dimension1IDs.length; i++) {
            let dim1Object = {
              id: dimension1IDs[i],
              parent: "0.0",
              name: newDimension1Array[i],
            };

            child1Array.push(dim1Object);
          }
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          //console.log(dimensions[1])

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            console.log(fiteredJson);

            //console.log(fiteredJson);
            //let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              // console.log(fObject);

              dimen2Json.push(fObject);
            });
          }

          let uniqDimen2Json = _.uniqWith(dimen2Json, _.isEqual);

          console.log(uniqDimen2Json);

          _.map(uniqDimen2Json, (dimen1) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            // let dsdsd = { [dimensions[1]]: dimen1[dimensions[1]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[1]],
            });

            //console.log(parentFilter[0].id);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[2]],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        if (dimension3Array.length === 0) {
        } else {
          let dimen3Json = [];

          for (let i = 0; i < dimension3Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
              [dimensions[3]]: dimension3Array[i],
            });

            //console.log(fiteredJson);

            // let chunkDimen2Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
                [dimensions[3]]: filJson[dimensions[3]],
              };

              //console.log(fObject);

              dimen3Json.push(fObject);
            });
          }

          // console.log(dimen2Json.length);

          let newDim3Array = [];

          _.map(dimen3Json, (dim1, index) => {
            let parentFilter = _.filter(child1Array, {
              name: dim1[dimensions[1]],
            });

            // console.log(parentFilter[0].id);

            let filterDim1 = _.filter(child2Array, {
              parent: parentFilter[0].id,
              name: dim1[dimensions[2]],
            });

            // console.log("filterDim1",filterDim1[0].id);

            let singleDimen1Id = _.uniqueId("3.");

            let dm1Object = {
              id: singleDimen1Id,
              //[dimensions[0]]:parentFilter[0].id,
              parent: filterDim1[0].id,
              name: dim1[dimensions[3]],
              value: selectedMetricArray[index],
            };

            //  console.log(dm1Object);

            newDim3Array.push(dm1Object);
          });

          // console.log(newDim1Array)

          child3Array = newDim3Array;
        }

        data = [
          ...parentObject,
          ...child1Array,
          ...child2Array,
          ...child3Array,
        ];
      } else {
        NotificationManager.error(
          "First Dimension should have only one value.",
          "Error"
        );
      }
    } else if (dimensions.length === 3) {
      console.log("Dimensions are only 3");

      let parentArrayUniqe = _.uniq(parentArray);

      if (parentArrayUniqe.length === 1) {
        let parentObject = [
          {
            id: "0.0",
            parent: "",
            //name: parentArrayUniqe[0],
            name: "Total",
          },
        ];

        let child1Array = [];
        let child2Array = [];

        let dimension1IDs = [];

        if (dimension1Array.length === 0) {
        } else {
          //Remove first element from array
          dimension1Array.shift();

          newDimension1Array = _.uniq(dimension1Array);

          //console.log("newDimension1Array:", newDimension1Array);

          for (let i = 0; i < newDimension1Array.length; i++) {
            let singleId = _.uniqueId("1.");

            dimension1IDs.push(singleId);
          }

          for (let i = 0; i < dimension1IDs.length; i++) {
            let dim1Object = {
              id: dimension1IDs[i],
              parent: "0.0",
              name: newDimension1Array[i],
            };

            child1Array.push(dim1Object);
          }
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          //console.log(dimensions[1])

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            console.log(fiteredJson);

            //console.log(fiteredJson);
            //let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              console.log(fObject);

              dimen2Json.push(fObject);
            });
          }
          //console.log(dimen2Json);

          let uniqDimen2Json = _.uniqWith(dimen2Json, _.isEqual);
          console.log(uniqDimen2Json);

          _.map(uniqDimen2Json, (dimen1, index) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            // let dsdsd = { [dimensions[1]]: dimen1[dimensions[1]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[1]],
            });

            //console.log(parentFilter[0].id);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[2]],
              value: selectedMetricArray[index],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        data = [...parentObject, ...child1Array, ...child2Array];
      } else {
        let parentObject = [
          {
            id: "0.0",
            parent: "",
            //name: sheetName,
            name: "Total",
          },
        ];

        let child1Array = [];
        let child2Array = [];
        let child3Array = [];

        if (parentArray.length === 0) {
        } else {
          let parentIDs = [];

          let parentArrayUniqe = _.uniq(parentArray);

          for (let i = 0; i < parentArrayUniqe.length; i++) {
            let singleParentId = _.uniqueId("1.");

            parentIDs.push(singleParentId);
          }

          //console.log("parentIDs:",parentIDs);

          // let parentObject = [];

          for (let i = 0; i < parentIDs.length; i++) {
            let pObject = {
              id: parentIDs[i],
              parent: "0.0",
              name: parentArrayUniqe[i],
            };

            child1Array.push(pObject);
          }
        }

        if (dimension1Array.length === 0) {
        } else {
          // console.log(dimension1ArrayUnique)

          let dimen1Json = [];

          for (let i = 0; i < dimension1Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
            });

            //console.log(fiteredJson);
            // let chunkDimen1Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
              };

              // console.log(fObject);

              dimen1Json.push(fObject);
            });
          }

          // console.log(_.uniqWith(dimen1Json, _.isEqual));

          let uniqDimen1Json = _.uniqWith(dimen1Json, _.isEqual);

          _.map(uniqDimen1Json, (dimen1) => {
            let singleDimen1Id = _.uniqueId("2.");

            //console.log(singleDimen1Id);

            //let dsdsd = { [dimensions[0]]: dimen1[dimensions[0]] };

            //console.log(dsdsd);

            let parentFilter = _.filter(child1Array, {
              name: dimen1[dimensions[0]],
            });

            //console.log(parentFilter[0].id);

            let dimen1Object = {
              id: singleDimen1Id,
              parent: parentFilter[0].id,
              name: dimen1[dimensions[1]],
            };

            // console.log(dimen1Object);

            child2Array.push(dimen1Object);
          });
        }

        if (dimension2Array.length === 0) {
        } else {
          let dimen2Json = [];

          for (let i = 0; i < dimension2Array.length; i++) {
            let fiteredJson = _.filter(mainJson, {
              [dimensions[0]]: parentArray[i],
              [dimensions[1]]: dimension1Array[i],
              [dimensions[2]]: dimension2Array[i],
            });

            //console.log(fiteredJson);

            //let chunkDimen2Array = [];
            _.map(fiteredJson, (filJson) => {
              let fObject = {
                [dimensions[0]]: filJson[dimensions[0]],
                [dimensions[1]]: filJson[dimensions[1]],
                [dimensions[2]]: filJson[dimensions[2]],
              };

              //console.log(fObject);

              dimen2Json.push(fObject);
            });
          }

          // console.log(dimen2Json.length);

          let newDim1Array = [];

          _.map(dimen2Json, (dim1, index) => {
            let parentFilter = _.filter(child1Array, {
              name: dim1[dimensions[0]],
            });

            // console.log(parentFilter[0].id);

            let filterDim1 = _.filter(child2Array, {
              parent: parentFilter[0].id,
              name: dim1[dimensions[1]],
            });

            // console.log("filterDim1",filterDim1[0].id);

            let singleDimen1Id = _.uniqueId("3.");

            let dm1Object = {
              id: singleDimen1Id,
              //[dimensions[0]]:parentFilter[0].id,
              parent: filterDim1[0].id,
              name: dim1[dimensions[2]],
              value: selectedMetricArray[index],
            };

            //  console.log(dm1Object);

            newDim1Array.push(dm1Object);
          });

          // console.log(newDim1Array)

          child3Array = newDim1Array;
        }

        data = [
          ...parentObject,
          ...child1Array,
          ...child2Array,
          ...child3Array,
        ];
      }
    } else if (dimensions.length === 2) {
    }

    console.log("Data:", data);

    // setState({ data });

    setData(data);
    setMetric(e.target.value);
  };

  let changeColor =location.state.changeColor;
  let changeColor2 =location.state.changeColor2;
  let changeColor3 =location.state.changeColor3;
  let changeColor4 =location.state.changeColor4;
  let metric1Array =location.state.metric1Array;
  let metric2Array =location.state.metric2Array;

  let metrics =location.state.metrics;

  console.log("Metric Selected:", metric);

  if (metric === 1) {
    console.log("metric selected is 1");
  } else {
    console.log("metric selected is 0");
  }

  console.log("Series Data:", data);

  //let finalData = _.concat(data);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  const options = {
    chart: {
      height: "80%",
      backgroundColor: "transparent",
    },

    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        colors: [changeColor, changeColor2, changeColor3, changeColor4],
      },
      sunburst: {
        colors: [changeColor, changeColor2, changeColor3, changeColor4],
      },
    },
    series: [
      {
        type: "sunburst",
        data: data,
        allowDrillToNode: true,
        cursor: "pointer",
        dataLabels: {
          format: "{point.name}",
          filter: {
            property: "innerArcLength",
            operator: ">",
            value: 16,
          },
          rotationMode: "circular",
        },
        point: {
          events: {
            click(e) {
              // let series = this.series;
              let clickedLevel = this.node.level;
              // currentOptions = series.userOptions.levels;
              console.log("Level Selected:", clickedLevel);

              //setLevel(clickedLevel);

              if (metric1Array[0] === undefined) {
              } else if (metric2Array[0] === undefined) {
              } else {
                if (clickedLevel === 1) {
                  document.getElementById("metricDropDown").disabled = false;
                  document.getElementById("displayMsg").innerHTML = "";
                } else if (clickedLevel === 2) {
                  document.getElementById("displayMsg").innerHTML =
                    "To activate the metric selection, please click at the center of the chart after resetting the chart to default.";
                  document.getElementById("metricDropDown").disabled = true;
                } else if (clickedLevel === 3) {
                  document.getElementById("displayMsg").innerHTML = "";
                  document.getElementById("metricDropDown").disabled = true;
                } else if (clickedLevel === 4) {
                  document.getElementById("displayMsg").innerHTML = "";
                  document.getElementById("metricDropDown").disabled = true;
                } else {
                  document.getElementById("metricDropDown").disabled = false;
                  document.getElementById("displayMsg").innerHTML = "";
                }
              }
              // }
            },
          },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            // color:"#000",
            dataLabels: {
              filter: {
                property: "outerArcLength",
                operator: ">",
                value: 64,
              },
            },
          },
          {
            level: 2,
            //color:changeColor2,
            colorByPoint: true,
          },
          {
            level: 3,
            // color:changeColor3,
            colorVariation: {
              key: "brightness",
              to: -0.5,
            },
          },
          {
            level: 4,
            // color:changeColor4,
            colorVariation: {
              key: "brightness",
              to: 0.5,
            },
          },
        ],
      },
    ],
    tooltip: {
      valueDecimals: 0,
      headerFormat: "",
      pointFormat: chartTooltip,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: -5,
              },
              title: {
                text: null,
              },
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
  };

  let metricsOptions = _.map(metrics, (metric, index) => {
    return (
      <option key={index} value={metric}>
        {metric}
      </option>
    );
  });

  console.log("metric2Array:", metric2Array);

  //console.log("Selected Level:", level);

  let metricDropDown;
  if (metric1Array[0] === undefined) {
    metricDropDown = "";
  } else if (metric2Array[0] === undefined) {
    metricDropDown = "";
  } else {
    metricDropDown = (
      <Form.Control
        id="metricDropDown"
        as="select"
        className="select_box"
        value={metric}
        onChange={onSelectMetric}
      >
        {metricsOptions}
      </Form.Control>
    );
  }

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col md="3" className="d-sm-none d-md-block"></Col>
        <Col md="3" className="d-sm-none d-md-block"></Col>
        <Col md="3" className="d-sm-none d-md-block"></Col>
        <Col md="3" sm="12">
          {metricDropDown}
          <span id="displayMsg" className="displayMsg"></span>
        </Col>
        <Col md="2" className="d-sm-none d-md-block"></Col>
        <Col md="8" sm="12">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Col>
        <Col md="2" className="d-sm-none d-md-block"></Col>
      </Row>
      <NotificationContainer />
      
     
    </Container>
  );
};

export default Chart;
