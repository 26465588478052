import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import GetStarted from "./components/GetStarted";
import Chart from "./components/Chart";
import ViewChart from "./components/ViewChart";

function App() {
  return (
    <Router>
      <div className="App">
       <Routes>
        <Route  path="/" element={<GetStarted />} />
        <Route  path="/chart" element={<Chart />} />
        <Route  path="/viewChart" element={<ViewChart />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
